// extracted by mini-css-extract-plugin
export var ArtistDescription = "Kaws-module--ArtistDescription--tu4TL";
export var ArtistInfos = "Kaws-module--ArtistInfos--ivirw";
export var ButtonWrapper = "Kaws-module--ButtonWrapper --4Ehh+";
export var CardWrapper = "Kaws-module--CardWrapper--OE35w";
export var CarrouselWrapper2 = "Kaws-module--CarrouselWrapper2--N4JI0";
export var Citations = "Kaws-module--Citations--awasM";
export var DescriptionWrapper = "Kaws-module--DescriptionWrapper--9pEFl";
export var ImageWrapper = "Kaws-module--ImageWrapper--aLO06";
export var LinkWrapper = "Kaws-module--LinkWrapper--5ZEUm";
export var MobileProtrait = "Kaws-module--MobileProtrait---P-qI";
export var More = "Kaws-module--More--Jhqy6";
export var MoreButton = "Kaws-module--MoreButton--JLVfP";
export var NameWrapper = "Kaws-module--NameWrapper--ukr7P";
export var PdpWrapper = "Kaws-module--PdpWrapper--fl38b";
export var PhotosWrapper = "Kaws-module--PhotosWrapper--tobWa";
export var ProfilWrapper = "Kaws-module--ProfilWrapper--dwmdX";
export var TitleWrapper = "Kaws-module--TitleWrapper--u87pB";
export var Wrapper = "Kaws-module--Wrapper--pgU1V";